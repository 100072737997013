
.fistheadingSearch{
    padding: 30px 15px 0px 15px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    .firstcomponet{
        display: flex;
        column-gap: 15px;
        h1{
            color: #000;
font-family: "IBM Plex Sans";
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
        }
    }
    .secondCompknet{
        .customSpaceCompact{
            border-bottom: 1px solid var(--Border-border-strong-01, #8D8D8D);
            background: var(--Gantt-Chart-Library-Outline-gantt-tag-group-background, #FFF);
        }
    }
}
.tableGraph{
    margin: 20px;
    display: flex;
    background-color: #FFF;
    .tableSettlemts{
        padding: 10px;
        background: #FFF;
    }
    .settlemtTableeg {
        padding: 12px;
        display: flex;
        justify-content: space-between;
        text-align: left;
        align-items: center;
        background: var(--Layer-accent-layer-accent-01, #E0E0E0);
        div{
            div{
                text-align: left;
                width: 100%;
                overflow: hidden;
                color: var(--Text-text-primary, #161616);
                text-overflow: ellipsis;
                font-family: "IBM Plex Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px; /* 128.571% */
                letter-spacing: 0.16px;
            }
            span{
                color: var(--Text-text-primary, #161616);
                font-family: "IBM Plex Sans";
                 font-size: 16px;
              font-style: normal;
              font-weight: 400;
                line-height: normal;
            }
        }
    }
    
    .tablesettlemtsData {
        display: flex;
        justify-content: space-between;
        text-align: center;
        align-items: center;
        div{
            border: 1px solid #E6EFF5;
            padding: 10px;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            color: var(--Text-text-primary, #161616);
            text-overflow: ellipsis;
            font-family: "IBM Plex Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .fistheadTable{
        padding: 20px 15px 15px 15px;
        display: flex;
        justify-content: space-between;
        text-align: center;
        align-items: center;
        .tableHeadalignf{
            display: flex;
            column-gap: 15px;
            h1{
                color: #000;
                font-family: "IBM Plex Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
        .secondSelctTable{
                border-bottom: 1px solid var(--Border-border-strong-01, #8D8D8D);
                background: var(--Gantt-Chart-Library-Outline-gantt-tag-group-background, #FFF);  
        }
    }
    .tableGraphItem {
        min-width: 0;
        flex: 1;
        .graphUpperItem{
            display: flex;
            justify-content: flex-end;
            column-gap: 12px;
            align-items: center;
            div{
                :where(.css-dev-only-do-not-override-11xg00t).ant-switch.ant-switch-checked {
                    background: var(--Support-support-success, #24A148);
                  }
                span{
                    color: var(--Text-text-primary, #161616);
    font-family: "IBM Plex Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.16px;
                }
            }
        }
    
        .underGrpahItem{
            display: flex;
            align-items: center;
            justify-content: space-between;
           margin: 20px;
            h1{
                color: #000;
                font-family: "IBM Plex Sans";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px; /* 128.571% */
                letter-spacing: 0.16px;  
            }
            div{
                color: var(--Text-text-on-color, #FFF);

/* Body styles/body-compact-01 */
font-family: "IBM Plex Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 128.571% */
letter-spacing: 0.16px;
background: var(--Button-button-primary, #0F62FE);
padding: 15px 60px 15px 15px;
            }
        }

      }
}

@media (max-width: 900px) {
    .tableGraph {
      margin: 20px;
      display: grid;
      background-color: #FFF;
    }
    .fistheadingSearch .firstcomponet h1 {
        width: 60%;
        color: #000;
        font-family: "IBM Plex Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }    
  }



