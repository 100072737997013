.mainContain {
    padding: 40px 30px;

    .transaction {
        color: #000;
        font-family: "IBM Plex Sans";
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
    }

    .suntaginactive {
        color: var(--Text-text-secondary, #525252);
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 128.571% */
        letter-spacing: 0.16px;
    }

    .suntag {
        color: var(--Text-text-primary, #161616);

        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        /* 128.571% */
        letter-spacing: 0.16px;
    }

    .datesearchFi {
        column-gap: 30px;
        display: flex;
        justify-content: space-between;

    }

    .inputfatef {
        display: flex;
        align-items: center;
        gap: 10px;
        span {
            color: #000;
            font-family: "IBM Plex Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 133.333% */
            letter-spacing: 0.32px;
            padding: 5px 4px;
        }

    }

    .transactioSelct {
        background-color: #FFF;
        overflow: hidden;
        color: var(--Text-text-primary, #161616);
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 128.571% */
        letter-spacing: 0.16px;
        padding: 15px 16px;
        border: none;
    }

    .inputSearch {
        border: none !important;
        padding: 15px 16px;
        color: var(--Text-text-placeholder, #A8A8A8);
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 128.571% */
        letter-spacing: 0.16px;
    }

    .cardClass {
        column-gap: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .cardpayment {
            width: 40%;
            margin-top: 20px;
            padding: 8px 23px;
            background: #FFF;
            border-radius: 8px;
            div {
                color: #718EBF;
                font-family: "IBM Plex Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            span {
                color: var(--Support-support-success-inverse, #42BE65);
                font-family: "IBM Plex Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
}

.exportBtnDiv {
    text-align: end;
    
    .exportBtn {
        background-color: #2a901b;
        border-color: #2a901b;
        color: white;
    }

    .not-allowed {
        cursor: not-allowed;
        background-color: #525252;
        border-color: #161616;
        color: white;
    }
}