.main {
    margin: 20px 25px 10px 25px;
    display: grid;
    align-content: start;
    grid-template-columns: 40% 60%;
    @media screen and (max-width: 1024px) {
        grid-template-columns: 50% 50%;
    }
    grid-template-rows: auto auto;  /* Adjusting rows for better control */
    gap: 20px;
}

.part1, .part2 {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: 87vh;
    // max-height: 70vh;
}

.part1 {
    border-radius: 8px 8px 0px 0px;
}

.part2 {
    border-radius: 8px 0px 0px 8px;
}

.part1::-webkit-scrollbar,
.part2::-webkit-scrollbar {
    width: 0;
}

.part3 {
    grid-column: 1 / -1; /* Span across all columns */
    width: 100%;  /* Ensure it fills the full available width */
    margin-top: 20px; /* Optional for spacing between parts */
}

.internalPart2 {
    column-gap: 20px;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    justify-content: flex-end;
    height: 100%;
}
