.plutos-logo {
    position: absolute;
    bottom: 10px;
    left:2.5rem;
    right: 2.5rem;

    img {
        height: 40px;
    }
}

.AppSidebar {
    position: relative;
}
