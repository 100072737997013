 /* Container Styles */
 .recon-container {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

/* Icon Sizes */
.icon-large {
  height: 28px;
  width: 28px;
}

.icon-medium {
  height: 24px;
  width: 24px;
}

.filter-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  padding: 8px 16px;
  width: 100%;
}

.filter-select {
  width: 200px;
}

.filter-date-picker {
  width: 200px;
}

.filter-search-bar {
  width: 200px;
}

.filter-button {
  background: #073763;
  color: white;
  transition: background-color 0.3s;
  border: none;
  padding: 6px 16px;
  cursor: pointer;
}

.filter-button:hover {
  background-color: #2a901b !important;
  border: #2a901b !important;
  color: white !important;
}

.form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 8px 0px;
  width: 100%;
}

.filter-container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 4px;
  width: 100%;
  padding: 8px;
}

.header-container {
  display: flex;
  justify-content: flex-end;
  margin: 16px;
}

.toggle-button {
  background: #073763;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-button:hover {
  background-color: #2a901b !important;
  // border: #2a901b !important;
  color: white !important;
}


.filter-select,
.filter-date-picker,
.filter-button {
  @media screen and (max-width: 768px) {
    width: 200px;
  }

  width: 100%;
}

.upload-button {
 width: 200px;
}

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dev-table {
  margin: 12px
}


.ant-upload-text {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}

.ant-upload-hint {
  font-family: 'IBM Plex Sans', sans-serif;
  letter-spacing: 1px;
}

.toggle-container {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  width: 90%;
  margin-top: 16px;
  margin-left: 36px;
}