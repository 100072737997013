.AppHeader {
    z-index: 1;
    width: 100%;
    position: fixed;
    display: flex;
    height: 60px;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid var(--Layer-layer-hover-02, #E8E8E8);
    background: #FFF;

    .logoIcon {
        width: 157px;
        height: 40px;
        object-fit: cover;
    }

    .alignNotification {
        display: flex;
        column-gap: 20px;
        align-items: center;

        div {
            justify-content: center;
            // display: grid;
            align-items: center;
        }
        .fixed-width-select {
            width: 150px; /* Set the desired width */
          }
        div>.notifiction {
            color: #000;
            text-align: right;
            font-family: "IBM Plex Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .imgIcon {
        width: 26px;
        height: 26px;
        flex-shrink: 0;
    }
}

.notificationsData {
    display: flex;
    flex-direction: column;
    min-height: 20px;
    width: 25vw;
    gap: 10px;
  
    .notification-item {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 5px 10px;
      cursor: pointer;
  
    }
    .notification-item:hover{
      background-color: rgb(244, 244, 244);
      border-radius:4px;
    }
}

.userActivity {
    width: 10px;
    height: 10px;
    display: block;
    background-color: white;
    position: absolute;
    bottom: 0;
    right: 3px;
    border: 1px solid #ffffff;
}




// <=====================Profile Csss==============================>

.circle {
    display: inline-block;


}

.circle-inner {
    color: white;
    text-align: center;
    height: 42px;
    width: 42px;
    font-size: 27px;
    background-color: #073763;
    border-radius: 50%;
    margin-top: -2px;
}

.circle-inner-profile {
    color: white;
    text-align: center;
    height: 81px;
    width: 81px;
    font-size: 48px;
    background-color: #073763;
    border-radius: 50%;
}

.profileWrapper {
    position: relative;
    width: 280px;
    height: auto;
    background: #fff;
    padding: 15px 15px 15px 15px;


    .profile {
        text-align: center;

        .name-profile {
            padding: 1px;
            margin: -12px 0 0 0;
        }

    }


    /* css  */
    .email-content {
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 7px 0px;

        .gmail-p {
            margin-left: 12px;
            font-size: 15px;
            margin-top: 1px;

            a {
                text-decoration: none;
                color: gray;

            }

            span {
                color: gray;
            }
        }
    }

    .social-icons {
        margin-top: 8px;
        border-top: 0.5px solid #c4b5b5;
        font-weight: 500;
    }


}

// <=====================Profile Css==============================>