.password-main-div {
  background-image: linear-gradient(rgba(255, 255, 255, 0.225), rgba(255, 255, 255, 0.244)), url('../../assets/images/rewe.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.password-container {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  width: 90%;
  max-width: 1000px;
  padding: 20px;
  display: flex;
  @media  (min-width:768px) {
    height: 70vh;
  }
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  margin:auto;

  // @media (min-width: 668px) {
      flex-direction: row;
  // }
}

#main-div {
  // img {
  //   display: none;
  // }

  @media (min-width: 576px) and (max-width: 980px) {
    img {
      display: flex;
    }
    #form-div {
      .ant-form-item-explain-error{
        margin-left: 10px;
      }
      input {
        width: 237.5px;
        margin: 0px 0px 0px 10px;
      }
      button {
        width: 237.5px;
        margin: 0px 0px 0px 10px;
      }
      span {
        margin: 0px 0px 0px 10px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 980px) {
    width: 500px;
    img {
      display: flex;
      height: auto;
      width: 85%;
      margin: 0px 0px 0px 10px;
    }
    #form-div {
      input {
        width: 237.5px;
        // margin: 0px 0px 0px 10px;
      }
      button {
        width: 237.5px;
        // margin: 0px 0px 0px 10px;
      }
    }
  }

  @media (min-width: 981px) {
    display: flex;
    flex-direction: row;
    img {
      display: flex;
      height: auto;
      width: 80%;
      margin: 0px 0px 0px 20px;
    }
    #form-div {
      .ant-form-item-explain-error{
        margin-left: 10px;
      }
      input {
        width: 237.5px;
        margin: 0px 0px 0px 10px;
      }
      button {
        width: 237.5px;
        margin: 0px 0px 0px 10px;
      }
    }
    #form-div > span {
      margin-left:10px;
    }
  }

  @media (min-width:992px){
    display: flex;
    width: 80vw;
  }

  // #form-div {
  //   @media (min-width:981px) {
  //     Input{
  //       border: 1px solid red;
  //       width: 250px;
  //     }
  //     Button{
  //       width: 250px;
  //     }
  //   }
  // }
}
