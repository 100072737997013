* {
  margin: 0;
}

.partDashboard{
  width: 100%;
  position: absolute;
  margin-top: 60px;
  display: grid;
  grid-template-columns: 18% 82%;
}

.AppSidebar {
  width: 100%;
  /* height: 100vh; */
  background: #FFFFFF;
  box-shadow: 0px 2px 6px 0px #0000004D;
}

.AppSidebar>div{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
.AppPageConntent{
  background: #F2F2F2;
  overflow: auto !important;
  height: 91vh;
}

.AppPageConntent::-webkit-scrollbar{
  width: 0;
}

li.ant-menu-item.ant-menu-item-only-child {
  background: #F4F4F4;
  border-radius: 0;
}



li.ant-menu-item.ant-menu-item-active.ant-menu-item-selected.ant-menu-item-only-child {
  background-color: #073763;
  color: white;
}

li.ant-menu-item.ant-menu-item-selected {
  background-color: #073763;
  color: white;
}

:where(.css-dev-only-do-not-override-11xg00t).ant-select .ant-select-arrow {
  top: 54%;
}
:where(.css-11xg00t).ant-select .ant-select-arrow{
  top: 54%;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #073763;
  /* border-color: #0094b3 */
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  /* border-color: #0094b3 */
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem
}



/* ====================ANTD DESIGN PAGINTION CSS=============================== */

.ant-table-cell,
.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td,
.ant-table-wrapper tfoot>tr>th,
.ant-table-wrapper tfoot>tr>td {
  position: relative;
  padding: 10px;
  overflow-wrap: break-word;
}


.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
  background: #d9d3d3;
  border-bottom: 1px solid #000;

}

.ant-btn-primary {
  color: #fff;
  background: #041e36;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background: #041e36;
}

