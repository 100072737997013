//transaction pages
.headingTag {
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: space-between;

    h1 {
        color: #000;
        font-family: "IBM Plex Sans";
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
    }

    div {
        color: #0F62FE;
        font-family: "IBM Plex Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 137.5% */
        cursor: pointer;
    }
}

.tableStyle {
    padding: 10px 20px;
    border-radius: 8px;
    background: #FFF;
    // height: 82vh;
    overflow-y: auto;

}

.tableStyle::-webkit-scrollbar {
    width: 0;
}

.transactionData {
    margin-bottom: 10px;
    border-bottom: 1px solid #E8E8E8;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width:1180px) {
        width: 20rem;
    }

    div>h1 {
        color: #232323;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    div>h2 {
        color: #718EBF;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .price {
        display: grid;
        align-items: center;
        justify-content: center;

        h3 {
            color: #42BE65;
            text-align: right;
            font-family: "IBM Plex Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .h3Success {
            color: #389e0d
        }

        .h3Failure {
            color: #cf1322
        }

        .h3Pending {
            color: #d4b106
        }

        h4 {
            text-align: center;
            font-family: "IBM Plex Sans";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            padding: 3px;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            box-sizing: border-box;
            width: 80px;
        }

        .h4Success {
            color: #389e0d;
            background: #f6ffed;
            border-color: #b7eb8f;
        }

        .h4Failure {
            color: #cf1322;
            background: #fff1f0;
            border-color: #ffa39e;
        }

        .h4Pending {
            color: #d4b106;
            background: #feffe6;
            border-color: #ebe786;
        }
    }
}



///simple data scss
.headingCard {
    color: #000;
    font-family: "IBM Plex Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;

    span {
        color: #000;
        font-family: "IBM Plex Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 0.2px;
    }
}

.cardItems {
    align-items: center;
    padding: 15px;
    justify-content: space-between;
    display: flex;
    background: #FFF;

    .cardleftitmes {
        div {
            color: #718EBF;
            font-family: "IBM Plex Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        span {
            color: #42BE65;
            font-family: "IBM Plex Sans";
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .cardrightitmes {
        color: #0E6027;
        text-align: right;
        font-family: "IBM Plex Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        display: inline-flex;
        padding: 4px 15px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background: var(--gantt-tag-group-background, #DEFBE6);
    }

}

.nextHeadingTag {
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: space-between;

    h1 {
        margin-top: 20px;
        color: #000;
        font-family: "IBM Plex Sans";
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
    }

    div {
        color: var(--Link-link-primary, #0F62FE);
        font-family: "IBM Plex Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 137.5% */
        cursor: pointer;
    }
}

.nextCard {
    background: #FFF;
    padding: 15px;

    div {
        color: #718EBF;
        font-family: "IBM Plex Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    span {
        color: var(--Support-support-success-inverse, #42BE65);
        font-family: "IBM Plex Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.lastcardSection {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #FFF;

    div {
        padding: 5px;
        display: grid;
        align-items: center;
        justify-content: center;
        text-align: center;

        h1 {
            color: #718EBF;
            font-family: "IBM Plex Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        h2 {
            color: var(--Support-support-success-inverse, #42BE65);
            font-family: "IBM Plex Sans";
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}

//billerperformace data 
.tableComplaint {
    padding: 10px;
    // background: #FFF;
}

.tableHead {
    // padding: 12px;
    display: flex;
    justify-content: space-between;
    text-align: left;
    align-items: center;

    div {
        width: 100%;
        color: #718EBF;
        font-family: "IBM Plex Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.tableData {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    text-align: left;
    align-items: center;
    border-bottom: 1px solid #E6EFF5;

    div {
        width: 100%;
        color: #232323;
        font-family: "IBM Plex Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}


.cssGraph {
    background-color: #FFF;
    border-radius: 8px;
}

.pie-h1 {
    color: #000;
    font-family: "IBM Plex Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
}


.headingGrpah {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    h4 {
        color: #000;
        font-family: "IBM Plex Sans";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.2px;
    }

    h5 {
        color: var(--Link-link-primary, #0F62FE);
        text-align: right;
        font-family: "IBM Plex Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}


.apexcharts-gridline {
    stroke-dasharray: 5;
    /* Adjust the dash length as needed */
}

.piechart {
    display: grid; /* Change to grid */
    grid-template-columns: 60% 35%; /* First div takes 2/3, second div takes 1/3 */
    gap: 20px; /* Space between the two divs */
    
    @media screen and (max-width: 1024px) {
        grid-template-columns: 90%; /* Stack the divs vertically on smaller screens */
    }
}