// Variables
$background: #f5f6fa;
$text: #9c9c9c;
$input-bg-color: #fff;
$input-text-color: #a3a3a3;
$button-bg-color: #4e65fc;
$button-text-color: #fff;

// Main container
.login-main-div {
    background-image: linear-gradient(rgba(255, 255, 255, 0.225), rgba(255, 255, 255, 0.244)), url('../../assets/images/rewe.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

// Login container
.login-container {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    width: 90%;
    max-width: 1000px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:auto;

    @media (min-width: 668px) {
        flex-direction: row;
    }
}

// Login image
.login-image {
    display: none;

    @media (min-width: 769px) {
        display: block;
        flex: 1;
        img {
            max-width: 80%;
            height: auto;
            border-radius: 10px;
        }
    }
}

// Login form
.login-form {
    flex: 1;
    max-width: 400px;
    width: 100%;
    padding: 20px;

    h1 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        text-align: center;
    }

    .ant-form-item {
        margin-bottom: 1rem;
    }

    .ant-btn {
        width: 100%;
        background-color: #164cff;
    }

    .ant-input, .ant-input-password {
        border-radius: 6px;
        height: 40px;
    }

    .ant-input-password{
        align-items: center;
    }
}

.main-otp{
    min-width: 200px;
    @media (min-width:540px) and (max-width:767px) {
        min-width: 350px
    }
    @media (min-width:768px) and (min-width:991px) {
        min-width: 380;
    }
    @media (min-width:992px) {
        min-width: 500px;
    }
}

// OTP field
.otp-field {
    flex-direction: row;
    column-gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.otp-field input {
    height: 30px;
    width: 30px;
    border-radius: 6px;
    outline: none;
    font-size: .8rem;
    @media (min-width:992px) {
        font-size: 1.125rem;
        height: 45px;
        width: 42px;
    }
    text-align: center;
    border: 1px solid #ddd;
}

.otp-field input:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.otp-field input::-webkit-inner-spin-button,
.otp-field input::-webkit-outer-spin-button {
    display: none;
}

// Resend
.resend {
    font-size: 12px;
}

// Footer
.footer {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: black;
    font-size: 12px;
    text-align: right;
    font-family: monospace;

    a {
        color: black;
        text-decoration: none;
    }
}

// Custom button
.custom-btn {
    display: inline-block;
    background: transparent;
    color: inherit;
    font: inherit;
    border: 0;
    outline: 0;
    padding: 0;
    margin-top: 10px;
    transition: all 200ms ease-in;
    cursor: pointer;

    &--primary {
        background: $button-bg-color;
        color: $button-text-color;
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, .1);
        border-radius: 10px;
        padding: 8px 12px;

        &:hover {
            background: darken($button-bg-color, 4%);
        }

        &:active {
            background: $button-bg-color;
            box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, .2);
        }
    }

    &--inside {
        margin-left: -96px;
    }
}

// Custom form
.custom-form {
    &__field {
        width: 100%;
        max-width: 360px;
        background: $input-bg-color;
        color: $input-text-color;
        font: inherit;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .1);
        border: 0;
        outline: 0;
        padding: 8px 12px;
        border-radius: 10px;
    }
}

// Title auth
.title-auth {
    text-align: center;
    @media screen and (max-width:540px) {
        width: 200px;
    }
    display: flex;
    flex-direction: column;
    justify-self: center;

    h5{
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        @media (min-width:540px) and (max-width:991px) {
            font-size: 20px;
            line-height: 28px; 
            width: 280px;
        }
        text-align: center;
        // white-space: nowrap;
        line-height: 24px;
        color: #101828;
    }
    @media (min-width:992px) {
        
        h5 {
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: #101828;
        }
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #667085;
        margin-top: 4px;
    }
}
